<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <div class="row">
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            :label="$t('globalTrans.fiscal_year')"
                                                            label-for="fiscal_year_id"
                                                            >
                                                            <b-form-select
                                                                disabled
                                                                plain
                                                                v-model="formData.fiscal_year_id"
                                                                :options="fiscalList"
                                                                id="fiscal_year_id"
                                                                >
                                                                <template v-slot:first>
                                                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Disburse Subsidy Amount" vid="allocated_subsidy_amount">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="subsidy_amount"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.allocated_subsidy_amount')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="subsidy_amount"
                                                            v-model="formData.subsidy_amount"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            disabled
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                 <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Bank" vid="bank_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="bank_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                {{ $t('bsri_demandDIS.bank') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                               disabled
                                                                plain
                                                                v-model="formData.bank_id"
                                                                :options="bankList"
                                                                id="bank_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                 <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                   <ValidationProvider name="branch" vid="branch_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="branch_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('bsri_demandDIS.branch') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            disabled
                                                            plain
                                                            v-model="formData.branch_id"
                                                            :options="branchList"
                                                            id="bank_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Cheque No" vid="cheque_no" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="cheque_no"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.cheque_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            disabled
                                                            id="cheque_no"
                                                            v-model="formData.cheque_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                     <ValidationProvider name="Cheque Date" vid="cheque_date" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="cheque_date"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('bsri_demandDIS.cheque_date')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input class="form-control"
                                                                disabled
                                                                v-model="formData.cheque_date"
                                                                placeholder="Select Date"
                                                                id="datepicker"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Comments" vid="comment">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="comment"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comment')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="comment"
                                                            v-model="formData.comment"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            readonly
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Receive Amount" vid="received_amount" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="received_amount"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.receive_amount')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            readonly
                                                            type="number"
                                                            id="received_amount"
                                                            v-model="formData.received_amount"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                     <ValidationProvider name="Received Date" vid="received_date" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="received_date"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('bsri_demandDIS.receive_date')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input class="form-control"
                                                            readonly
                                                                v-model="formData.received_date"
                                                                placeholder="Select Date"
                                                                id="datepicker"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <!-- <b-button type="submit" variant="primary" class="mr-2">{{ $t('bsri_demandDIS.received')}}</b-button>
                                                      &nbsp; -->
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-2')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { fundReceivedShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            this.getLoadData()
        }
    },
    data () {
        return {
            loading: false,
            officerLoading: false,
            formData: {
                fiscal_year_id: 0,
                received_amount: '',
                received_date: ''
            },
            attachment_two: '',
            branchList: []
        }
    },
    watch: {
        'formData.bank_id': function (newVal) {
            this.branchList = this.getBranchList(newVal)
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        bankList: function () {
            const bankList = this.$store.state.commonObj.bankObj.bankList.filter(item => item.status === 0)
            return bankList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        onFileChange (e) {
            this.attachment_two = e.target.files[0]
        },
        getFormData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async getLoadData () {
            this.loading = true
            const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${fundReceivedShow}/${this.$props.id}`)
            if (result.success) {
                this.formData = result.data[0]
            }
            this.loading = false
        },
        getBranchList: function (bankId) {
            const branchList = this.$store.state.commonObj.bankObj.branchList.filter(item => item.status === 0 && item.bank_id === bankId)
            return branchList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    }
}
</script>
